import type { PropType, VNode } from 'vue';

export default defineComponent({
  name: 'Switch',
  props: {
    align: {
      default: 'left',
      type: String as PropType<'left' | 'right'>,
    },
    checked: {
      default: undefined,
      type: Boolean,
    },
    name: {
      default: undefined,
      type: String,
    },
    handleToggle: {
      default: undefined,
      type: Function as PropType<(event: Event) => void>,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    invertColor: {
      default: false,
      type: Boolean,
    },
    swapColor: {
      default: false,
      type: Boolean,
    },
  },
  render(): VNode {
    return (
      <label
        class={{
          'flex items-center justify-between gap-2': true,
          'flex-row-reverse': this.align === 'right',
        }}
      >
        <div class="relative cursor-pointer">
          <input
            type="checkbox"
            name={this.name}
            class="peer sr-only"
            checked={this.checked}
            onChange={this.handleToggle}
            disabled={this.disabled}
          />
          <div
            class={{
              "peer-checked:bg-rb-bright-green after:border-3 peer h-4 w-9 rounded-full bg-gray-200 after:absolute after:-left-2 after:-top-1 after:h-6 after:w-6 after:rounded-full after:border-current after:bg-white after:ring-2 after:ring-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-focus:ring-2 peer-focus:ring-current": true,
              'text-gray-200 peer-checked:bg-gray-200': this.disabled,
              'after:bg-rb-bright-red after:ring-rb-bright-red text-white peer-checked:bg-white':
                this.invertColor,
            }}
          />
        </div>
        <span class="text-sm font-medium">{this.$slots.default?.()}</span>
      </label>
    );
  },
});
